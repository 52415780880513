import Panel from "@magicware/dashboard/Panel";
import { buildForm } from "@magicware/forms/builder";
import Form, { FormImplementationProps } from "@magicware/forms/components/Form";
import FormGrid from "@magicware/forms/components/FormGrid";
import FormSelect from "@magicware/forms/mui/FormSelect";
import FormTextField from "@magicware/forms/mui/FormTextField";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { useAppInstanceId } from "../../navigation/appInstances";
import { updateDetail } from "../../redux/appInstanceVersions/appInstanceVersionsReducer";
import { AppInstanceVersionModelDetail, AppInstanceVersionModelNew } from "../../services/api/appInstanceVersions";
import { FormBaseAppVersionsAutocomplete } from "../baseAppVersions/FormBaseAppVersionsAutocomplete";
import { environmentOptions } from "../codebooks/environments";

export const appInstanceVersionForm = buildForm<AppInstanceVersionModelDetail>()(() => ({
    props: {
        remove: ["id", "version"],
        extra: [],
    },
    transformations: {
        toForm: { fromModel: (h, m: AppInstanceVersionModelDetail) => h.fromModel(m) },
        formTo: {
            new: (h, f, appInstanceId: number): AppInstanceVersionModelNew => ({
                ...h.toNewEntity(f),
                appInstanceId,
            }),
            update: (h, f, m: AppInstanceVersionModelDetail) => h.toUpdateEntity(f, m),
        },
    },
    updateReduxModel: updateDetail,
    schema: (_, t) => ({
        name: yup.string().required(t("name-is-required")),
        environment: yup.string(),
        baseAppVersion: yup.object(),
        notes: yup.string(),
    }),
}));

const f = appInstanceVersionForm.f;

export default function AppInstanceVersionForm(props: FormImplementationProps<typeof f>) {
    const { t } = useTranslation();
    const appInstanceId = useAppInstanceId();
    return (
        <Form validationSchema={appInstanceVersionForm.validationSchema} {...props}>
            <Panel>
                <FormGrid>
                    <FormTextField f={f} field="name" label={t("name-label")} autoFocus={props.autoFocus} />
                    <FormSelect f={f} options={environmentOptions} field="environment" label={t("environment-label")} />
                    <FormBaseAppVersionsAutocomplete
                        f={f}
                        field="baseAppVersion"
                        fullOption
                        label={t("baseAppVersion-label")}
                        filter={{ appInstanceId: appInstanceId }}
                    />
                    <FormTextField f={f} field="notes" label={t("notes-label")} multiline />
                </FormGrid>
            </Panel>
        </Form>
    );
}
