import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CloseIcon from "@mui/icons-material/Close";
import {
    FormControl,
    FormControlProps,
    FormHelperText,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    SelectProps,
} from "@mui/material";
import { useField, useForm } from "react-final-form";
import { FormFieldProps } from "../builder";
import useControlId from "../hooks/useControlId";
import useDisabled from "../hooks/useDisabled";
import useFieldName from "../hooks/useFieldName";

export interface FormSelectProps<Value extends string | number | undefined = string> {
    label?: SelectProps["label"];
    helperText?: React.ReactNode;
    searchIcon?: boolean;
    resetable?: boolean;
    resetValue?: Value;
    options?: [Value, React.ReactNode][];
}

export default function FormSelect<FormValues, Value extends string | number = string>({
    f: _,
    field,
    label,
    helperText,
    options,
    searchIcon = false,
    resetable = false,
    resetValue,
    children,
    disabled,
    ...props
}: FormFieldProps<FormValues, Value | undefined> & FormControlProps & FormSelectProps<Value>) {
    field = useFieldName(field);
    disabled = useDisabled(disabled);
    const id = useControlId();
    const formField = useField(field);
    const form = useForm();

    const isError = formField.meta.touched && Boolean(formField.meta.error || formField.meta.submitError);

    let startAdornment: SelectProps["startAdornment"] = undefined;
    if (searchIcon && !formField.input.value)
        startAdornment = <FontAwesomeIcon icon={faSearch} tw="text-gray-400 mr-1" />;

    let endAdornment: SelectProps["endAdornment"] = undefined;
    if (resetable && formField.input.value) {
        endAdornment = (
            <div tw="mr-5">
                <IconButton size="small" onClick={() => form.change(field, resetValue)}>
                    <CloseIcon fontSize="small" />
                </IconButton>
            </div>
        );
    }

    if (options) {
        children = options.map((o) => (
            <MenuItem key={o[0]} value={o[0]}>
                {o[1]}
            </MenuItem>
        ));
    }
    return (
        <FormControl fullWidth {...props} disabled={disabled}>
            {label && (
                <InputLabel id={id} error={isError}>
                    {label}
                </InputLabel>
            )}
            <Select
                labelId={id}
                label={label}
                {...formField.input}
                startAdornment={startAdornment}
                endAdornment={endAdornment}
                autoComplete="new-password"
                error={isError}
            >
                {children}
            </Select>
            {isError && <FormHelperText error>{formField.meta.error || formField.meta.submitError}</FormHelperText>}
            {!isError && helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
    );
}
