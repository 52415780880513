import Panel from "@magicware/dashboard/Panel";
import { buildForm } from "@magicware/forms/builder";
import Form, { FormImplementationProps } from "@magicware/forms/components/Form";
import FormGrid from "@magicware/forms/components/FormGrid";
import FormCheckBoxField from "@magicware/forms/mui/FormCheckBoxField";
import FormNumberField from "@magicware/forms/mui/FormNumberField";
import FormTextField from "@magicware/forms/mui/FormTextField";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { updateDetail } from "../../redux/devices/devicesReducer";
import { AppDispatch } from "../../redux/types";
import { CodebookOptionModel } from "../../services/api/codebooks";
import { DeviceModelDetail } from "../../services/api/devices";
import { BrandForm, brandFormToEntity, FormBrandsAutocomplete } from "../brands/FormBrandsAutocomplete";
import { FormCurrenciesAutocomplete } from "../codebooks/FormCurrenciesAutocomplete";
import { FormKeywordsAutocomplete, KeywordsForm, keywordsFormToEntities } from "../codebooks/FormKeywordsAutocomplete";
import { FormParametersAutocomplete } from "../parameters/FormParametersAutocomplete";

export const deviceForm = buildForm<DeviceModelDetail & KeywordsForm & BrandForm>()(() => ({
    props: {
        remove: ["id", "version", "keywordList"],
        extra: ["keywords"],
    },
    transformations: {
        toForm: { fromModel: (h, m: DeviceModelDetail) => ({ ...h.fromModel(m), keywords: m.keywordList }) },
        formTo: {
            new: async (h, f, dispatch: AppDispatch) => ({
                ...h.toNewEntity(f),
                ...(await keywordsFormToEntities(dispatch, f)),
                ...(await brandFormToEntity(dispatch, f)),
            }),
            update: async (h, f, dispatch: AppDispatch, m: DeviceModelDetail) => ({
                ...h.toUpdateEntity(f, m),
                ...(await keywordsFormToEntities(dispatch, f)),
                ...(await brandFormToEntity(dispatch, f)),
            }),
        },
        toReduxUpdateModel: (h, f): Partial<DeviceModelDetail> => ({
            ...h.removeExtra(f),
            keywordList: f.keywords.filter((t) => typeof t !== "string") as CodebookOptionModel[],
        }),
    },
    updateReduxModel: updateDetail,
    schema: (f, t) => ({
        name: yup.string().required(t("name-is-required")),
        fullName: yup.string(),
        brand: yup.mixed().required(t("brand-is-required")) as any,
        parameterList: yup.array().default([]),
        url: yup.string().url(t("invalid-url")),
        price: yup.number(),
        isPriceHidden: yup.boolean().default(false),
        currency: yup.object().requiredIf(f, "price", t("currency-is-required")),
        notes: yup.string(),
        keywords: yup.array().default([]),
    }),
}));

const f = deviceForm.f;

export type DeviceFormOnSubmit = FormImplementationProps<typeof f>["onSubmit"];

export default function DeviceForm(props: FormImplementationProps<typeof f>) {
    const { t } = useTranslation();
    return (
        <Form validationSchema={deviceForm.validationSchema} {...props}>
            <Panel>
                <FormGrid>
                    <FormTextField f={f} field="name" label={t("name-label")} autoFocus={props.autoFocus} />
                    <FormBrandsAutocomplete
                        f={f}
                        field="brand"
                        fullOption
                        label={t("brand-label")}
                        filter={{}}
                        freeSolo
                    />
                    <FormTextField f={f} field="fullName" label={t("full-name-label")} />
                    <FormTextField f={f} field="url" label={t("url-label")} />
                    <div tw="flex items-center">
                        <FormNumberField f={f} field="price" label={t("informative-price-label")} />
                        <FormCurrenciesAutocomplete
                            tw="w-36 ml-2"
                            f={f}
                            field="currency"
                            fullOption
                            label={t("currency-label")}
                        />
                        <FormCheckBoxField tw="ml-4" f={f} field="isPriceHidden" label={t("hide-price-label")} />
                    </div>
                    <FormKeywordsAutocomplete f={f} field="keywords" label="Tags" multiple fullOption freeSolo />
                    <FormParametersAutocomplete
                        f={f}
                        field="parameterList"
                        fullOption
                        multiple
                        label={t("parameters-label")}
                        filter={{}}
                    />
                    <FormTextField f={f} field="notes" label={t("notes-label")} multiline />
                </FormGrid>
            </Panel>
        </Form>
    );
}
