import { useTranslation } from "react-i18next";
import useDisabled from "../hooks/useDisabled";
import FormSubmitButton, { FormSubmitButtonProps } from "./FormSubmitButton";
import FormSubmitResult from "./FormSubmitResult";

export interface SavePanelProps extends FormSubmitButtonProps {
    buttonContent?: React.ReactNode;
    successMessage?: string;
}
// tw="box-shadow[rgba(0, 0, 0, 0.3) 0px 13px 50px 4px;]"
export default function SavePanel({ successMessage, buttonContent, ...props }: SavePanelProps) {
    const { t } = useTranslation();
    const disabled = useDisabled(props.disabled);
    if (disabled) return null;
    if (buttonContent === undefined) buttonContent = t("save-changes-button");
    return (
        <div tw="sticky [bottom:0px] pb-5 [z-index:1000] mt-5 pointer-events-none">
            <div tw="flex items-center">
                <div tw="[flex-grow:1] mx-4 relative">
                    <FormSubmitResult successMessage={successMessage} />
                </div>
                <div tw="[flex-grow:0] pointer-events-auto p-4">
                    <div tw="absolute blur-lg">
                        <FormSubmitButton hideOnPristine {...props}>
                            {buttonContent}
                        </FormSubmitButton>
                    </div>
                    <div>
                        <FormSubmitButton hideOnPristine {...props}>
                            {buttonContent}
                        </FormSubmitButton>
                    </div>
                </div>
            </div>
        </div>
    );
}
