import { CircularProgress } from "@mui/material";
import Button, { ButtonProps } from "@mui/material/Button";
import { useFormState } from "react-final-form";
import tw from "twin.macro";
import useDisabled from "../hooks/useDisabled";
//import SpinnerLoader from "react-loader-spinner";

export interface FormSubmitButtonProps {
    disableOnPristine?: boolean;
    ignoreDirty?: boolean;
    hideOnPristine?: boolean;
    disabled?: boolean;
}

export default function FormSubmitButton({
    children,
    ignoreDirty = false,
    disableOnPristine = false,
    hideOnPristine = false,
    disabled,
    ...props
}: FormSubmitButtonProps & ButtonProps) {
    disabled = useDisabled(disabled);
    const { submitting, pristine, dirty } = useFormState({
        subscription: { submitting: true, pristine: disableOnPristine || hideOnPristine, dirty: !ignoreDirty },
    });
    if (hideOnPristine && pristine) return null;
    return (
        <Button
            type="submit"
            color={dirty ? "error" : "primary"}
            disabled={disabled || submitting || (disableOnPristine && pristine)}
            {...props}
        >
            <span tw="relative">
                <span css={[submitting && tw`invisible`]}>{children}</span>
                {submitting && (
                    <span tw="absolute w-full left-0">
                        <CircularProgress size="24px" />
                    </span>
                )}
            </span>
        </Button>
    );
}
// <span tw="absolute w-full top-0 left-0 -mt-8 flex items-center justify-center transform[scale(30%)]">
//     <SpinnerLoader type="MutatingDots" color="#00BFFF" height={80} width={80} />{" "}
// </span>
